import { type RouteRecordRaw } from 'vue-router';

import { type AccessMeta } from '@/services/auth/decision';

const HandlingUnits: Array<RouteRecordRaw> = [
  {
    path: '/handling-unit/:id',
    name: 'Handling Unit',
    component: () => import('@/views/handling-unit/HandlingUnitPage.vue'),
    props: true,
    meta: {
      title: 'Handling',
      betaMode: true,
      access: {
        url: 'shipments/:id',
        method: 'GET',
        service: 'shipping',
        version: 'v2',
      } as AccessMeta,
    },
  },
];

export default HandlingUnits;
